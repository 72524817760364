import React, { useMemo } from 'react';

import { ICONS, ICON_SIZES, Icon } from '~/components/Icon';
import Tooltip, { TOOLTIP_PLACEMENTS } from '~/components/Tooltip';

import { Loader } from './components/Loader';
import {
  StyledCloseButton,
  StyledDeleteIconButton,
  StyledIconButton,
  StyledIconCircleButton,
  StyledNavigationPrimaryButton,
  StyledNavigationSecondaryButton,
  StyledPrimaryButton,
  StyledSecondaryButton,
  StyledSquareButton,
  StyledTextDeleteButton,
  StyledTextPrimaryButton,
} from './design';
import { ButtonVariant, DropdownIconState } from './types';

import type { IButtonProps } from './types';

const Button = (props: IButtonProps) => {
  const {
    label,
    variant = ButtonVariant.PRIMARY,
    icon,
    iconSize: defaultIconSize,
    isLoading,
    counter,
    dropDownIcon = DropdownIconState.NONE,
    tooltip,
  } = props;

  const isNavigationVariant = useMemo(
    () => [ButtonVariant.NAVIGATION_PRIMARY, ButtonVariant.NAVIGATION_SECONDARY].includes(variant),
    [variant],
  );

  const iconName = useMemo(() => {
    switch (variant) {
      case ButtonVariant.CLOSE:
        return ICONS.CLOSE;
      case ButtonVariant.ICON_DELETE:
        return ICONS.DELETE_BIN;
      default:
        return icon;
    }
  }, [icon, variant]);

  const iconSize = useMemo(() => {
    if (defaultIconSize) {
      return defaultIconSize;
    }

    switch (variant) {
      case ButtonVariant.CLOSE:
        return ICON_SIZES.LARGE;
      case ButtonVariant.SQUARE:
        return ICON_SIZES.SMALL;
      default:
        return ICON_SIZES.MEDIUM;
    }
  }, [defaultIconSize, variant]);

  const content = isLoading ? (
    <Loader />
  ) : (
    <>
      {iconName && (
        <div className="icon">
          <Icon icon={iconName} size={iconSize} />
        </div>
      )}
      {label && <span className="label">{label}</span>}
      {isNavigationVariant && (
        <div className="icon">
          <Icon icon={ICONS.NEXT} size={ICON_SIZES.SMALL} />
        </div>
      )}
      {!!counter && <div className="circleLabel">{counter}</div>}
      {dropDownIcon !== DropdownIconState.NONE && (
        <>
          {dropDownIcon === DropdownIconState.DOWN ? (
            <Icon icon={ICONS.DROPDOWN} size={ICON_SIZES.SMALL} />
          ) : (
            <div className="flip_vertically">
              <Icon icon={ICONS.DROPDOWN} size={ICON_SIZES.SMALL} />
            </div>
          )}
        </>
      )}
    </>
  );

  const COMPONENT_NAME = {
    [ButtonVariant.PRIMARY]: StyledPrimaryButton,
    [ButtonVariant.SECONDARY]: StyledSecondaryButton,
    [ButtonVariant.TEXT_PRIMARY]: StyledTextPrimaryButton,
    [ButtonVariant.TEXT_DELETE]: StyledTextDeleteButton,
    [ButtonVariant.SQUARE]: StyledSquareButton,
    [ButtonVariant.NAVIGATION_PRIMARY]: StyledNavigationPrimaryButton,
    [ButtonVariant.NAVIGATION_SECONDARY]: StyledNavigationSecondaryButton,
    [ButtonVariant.CLOSE]: StyledCloseButton,
    [ButtonVariant.ICON]: StyledIconButton,
    [ButtonVariant.ICON_DELETE]: StyledDeleteIconButton,
    [ButtonVariant.ICON_CIRCLE]: StyledIconCircleButton,
  };

  const handleClick: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    // block onClick action on loading
    if (isLoading) {
      event.preventDefault();
      event.stopPropagation();
      return;
    }
    props.onClick?.(event);
  };

  const SpecificButton = COMPONENT_NAME[variant];
  const btn = (
    <SpecificButton {...props} onClick={handleClick}>
      {content}
    </SpecificButton>
  );

  /* we wrap btn in div to display tooltip when btn is disabled */
  return tooltip ? (
    <Tooltip tooltip={tooltip} placement={TOOLTIP_PLACEMENTS.BOTTOM}>
      <div>{btn}</div>
    </Tooltip>
  ) : (
    btn
  );
};

export { Button };
